import React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import './Header.css';
import Link from '@mui/material/Link';


const Header: React.FC = () => {

  return (
    <header className="App-header">
      <nav>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" href="/">Home</Button>
          <Button variant="contained" href="/body">Body</Button>
          <Button variant="contained" href="/login">Login</Button>
          <Button variant="contained" href="/logout">Logout</Button>
        </Stack>
      </nav>
    </header>
  );
}

export default Header;



