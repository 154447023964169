import React, { useEffect, useState } from 'react';
import './Root.css';
import KobeRules from './home/KobeRules';

const Root: React.FC = () => {

  return (
    <div className='Root'>
      <div className="App">
        <KobeRules />
      </div>
    </div>
  );
}

export default Root;



