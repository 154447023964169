import React from 'react';
import './KobeRules.css';

const KobeRules: React.FC = () => {
  const rules = [
    "Get better every single day",
    "Prove them wrong",
    "Work on your weaknesses",
    "Execute what you practiced",
    "Learn from GREATNESS",
    "Learn from wins and losses",
    "Practice mindfulness",
    "Be ambitious",
    "#Believe in your team",
    "Learn storytelling"
  ];

  return (
    <div className="kobe-container">
      <h1>Kobe Bryant's 10 Rules for Success</h1>
      <ul>
        {rules.map((rule, index) => (
          <li key={index}>{rule}</li>
        ))}
      </ul>
    </div>
  );
}

export default KobeRules;
