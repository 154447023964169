import React from 'react';

const Contact: React.FC = () => {

  return (
    <div>
      Super Contact page
    </div>
  );
}

export default Contact;



